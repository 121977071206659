import {initNotFoundRecommenders, initLastViewedProductsRecommender} from "../../webpack/modules/recommender";
import {documentLoadEvent} from "../../webpack/modules/events";
import {post} from "../../webpack/modules/ajax";
import {getLastViewedProducts} from "../../webpack/modules/last-viewed-products";

documentLoadEvent(function() {
    initNotFoundRecommenders(false);
    renderLastViewedProductRecommender()
});

function renderLastViewedProductRecommender()
{
    post(
        '/ajax/website/product-list/get-last-viewed-products-recommender',
        {lastViewedProducts: getLastViewedProducts()},
        function(data) {
            let recommender = data.recommender;
            let container = document.querySelector('#last-viewed-products-container');
            container.innerHTML = recommender;
            initLastViewedProductsRecommender(false);
        }
    );
}